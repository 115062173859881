import React from 'react';

import * as styles from './FormInputField.module.css';

const FormInputField = ({
  id,
  type = 'text',
  labelName,
  value,
  pattern,
  min,
  max,
  handleChange,
  error,
  placeholder,
  choices,
  rightIcon,
  subOption=[],
  otherChoice='',
  otherChoiceId,
}) => {
  return (
    <>
      {type === 'checkbox' ? (
        <div className={styles.singleCheckbox}>
          <input
            id={id}
            type="checkbox"
            name={id}
            value={value}
            pattern={pattern}
            className={`${styles.input} ${error ? styles.fieldRequired : ''}`}
            onChange={e => handleChange(id, e.target)}
          /> <label htmlFor={id} dangerouslySetInnerHTML={{__html: labelName}}></label>
        </div>
      ): (
      <div className={`formField ${styles.formField}`}>
        <label htmlFor={id} className={styles.label} dangerouslySetInnerHTML={{__html: labelName}}>
        </label>
        {((type === 'text') || (type === 'input')) && (
          <input
            id={id}
            name={id}
            type="text"
            value={value}
            pattern={pattern}
            className={`${styles.input} ${error && error ? styles.fieldRequired : ''}`}
            onChange={e => handleChange(id, e.target.value)}
          />
        )}
        {(type === 'number') && (
          <input
            id={id}
            name={id}
            type="number"
            min={min}
            max={max}
            value={value}
            pattern={pattern}
            className={`${styles.input} ${error && error ? styles.fieldRequired : ''}`}
            onChange={e => handleChange(id, e.target.value)}
          />
        )}
        {(type === 'dropdown') && (
          <select
            id={id}
            name={id}
            value={value}
            pattern={pattern}
            className={`${styles.input} ${error && error ? styles.fieldRequired : ''} ${rightIcon && styles.customIcon}`}
            onChange={e => handleChange(id, e.target.value)}
          >
            <option value=''>Please Select</option>
            {choices.map((choice, key) => {
              return (<option value={choice} key={key}>{choice}</option>);
            })}
          </select>
        )}
        {(type === 'password') && (
          <input
            id={id}
            name={id}
            type="password"
            value={value}
            pattern={pattern}
            className={`${styles.input} ${error && error ? styles.fieldRequired : ''}`}
            onChange={e => handleChange(id, e.target.value)}
          />
        )}
        {(type === 'email') && (
          <input
            id={id}
            name={id}
            type="email"
            value={value}
            pattern={pattern}
            className={`${styles.input} ${error ? styles.fieldRequired : ''}`}
            onChange={e => handleChange(id, e.target.value)}
          />
        )}
        {(type === 'textarea') && (
          <textarea
            id={id}
            name={id}
            value={value}
            pattern={pattern}
            placeholder={placeholder}
            className={`${styles.textarea} ${error ? styles.fieldRequired : ''}`}
            onChange={e => handleChange(id, e.target.value)}
          />
        )}
        {(type === 'checkbox') && (
          <input
            id={id}
            type="checkbox"
            name={id}
            value={value}
            pattern={pattern}
            className={`${styles.input} ${error ? styles.fieldRequired : ''}`}
            onChange={e => handleChange(id, e.target)}
          />
        )}
        {(type === 'radiogroup') && (
          choices.map((choice, key) => {
            return (
              <div className={styles.radioGroupContainer}>
                <fieldset>
                  <input
                    id={id + '_' + key}
                    type="radio"
                    name={id}
                    value={choice}
                    pattern={pattern}
                    className={`${styles.input} ${error ? styles.fieldRequired : ''}`}
                    onChange={e => handleChange(id, e.target.value)}
                  /> <label htmlFor={id + '_' + key}>
                  {choice}
                </label>
                </fieldset>

                {subOption && subOption.value === choice && subOption.value === value && subOption.choices.map((obj) => (
                  <div className={styles.subOptionContainer}>
                    <fieldset>
                      <input
                        id={choice + '_' + obj}
                        type="radio"
                        name={subOption.name}
                        value={obj}
                        pattern={pattern}
                        className={`${styles.input}`}
                        onChange={e => handleChange(subOption.name, e.target.value)}
                      />
                      <label htmlFor={choice + '_' + obj}>
                        {obj}
                      </label>
                    </fieldset>
                  </div>))}
              </div>
            );
          })
        )}
        {(type === 'checkboxgroup') && (
          choices.map((choice, key) => {
            return (
              <fieldset>
                <input
                  id={id + '_' + key}
                  type="checkbox"
                  name={id}
                  value={choice}
                  pattern={pattern}
                  className={`${styles.input} ${error ? styles.fieldRequired : ''}`}
                  onChange={e => handleChange(id, e.target.value)}
                /> <label htmlFor={id + '_' + key}>
                {choice}
              </label>
              </fieldset>
            );
          })

        )}
        {!!rightIcon && <div className={`rightIcon ${styles.rightIcon}`}>{rightIcon}</div>}
      </div>)}
    </>
  );
};

export default FormInputField;
