/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useContext, useEffect } from "react"
import AuthContext from '../context/AuthProvider';
import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
// import Container from "../components/atoms/Container/Container"
// import PageTitle from "../components/atoms/PageTitle/PageTitle"
import Button from "../components/atoms/Button/Button"
import Dialog from "../components/atoms/Dialog/Dialog";
import FormInputField from "../components/atoms/FormInputField/FormInputField";
import { getStorage } from '../helpers/general'
import { graphql, Link } from "gatsby";

import * as styles from './create-account.module.css'

const ContentfulPageSignup = ({ data }) => {
    const auth = useContext(AuthContext);
    const handleRegistration = auth && auth.signup;
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    const userEmail = getStorage('_customEmail');
    const [fields, setFields] = useState({
        email: '',
        first_name: '',
        last_name: '',
        authentication: {'new_password': ''},
        confirm_password: ''
    });

    const [emptyError, setEmptyError] = useState({
        email: false,
        first_name: false,
        last_name: false,
        password: false,
        confirm_password: false
    })

    const [dialogMessage, setDialogMessage] = useState(false);

    useEffect(() => {
        if (isLoggedIn && typeof window !== 'undefined') {
            window.location = '/account';
        }
    }, [isLoggedIn])

    const attemptRegistration = (e) => {
        e.preventDefault();
        const valid = validateRequiredFields()

        if (valid) {
            if (validatePassword(fields['authentication'].new_password, fields['confirm_password'])) {
                handleRegistration(fields).then(response => {
                    console.log('response', response)
                }).catch(error => {
                    console.log('error', error)
                    setDialogMessage(error);
                });
            } else {
                setDialogMessage('Passwords do not match.');
            }
        } else {
            setDialogMessage('Please fill all available fields in.');
        }
    }

    const validateRequiredFields = () => {
        const newError = emptyError
        let isValid = true

        Object.keys(emptyError).forEach((item) => {
            if (item === 'password') {
                if (fields['authentication'].new_password !== '') {
                    newError['password'] = false
                } else {
                    newError['password'] = true
                    isValid = false
                }
            } else if (fields[item] !== '') {
                newError[item] = false
            } else {
                newError[item] = true
                isValid = false
            }
        });

        setEmptyError(newError)

        return isValid
    }

    const validatePassword = (pass, passConfirm) => {
        if (pass === passConfirm) {
            return true
        } else {
            return false
        }
    }

    const clearDialog = () => {
        setDialogMessage(false);
    }

    const handleChange = (id, fieldValue) => {
        const newField = {};

        if (id === 'password') {
            newField['authentication'] = {'new_password': fieldValue};
        } else {
            newField[id] = fieldValue;
        }
        
        setFields({...fields, ...newField});
    }

    const backgroundImage = data?.signupPage?.blocks[0]?.blocks[0]?.desktopImage.file.url;

    if (!isLoggedIn && userChecked) {
        return (
        <Layout>
            <Seo title="Sign up" />
            <div className={styles.signupContainer} style={!!backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : undefined}>
                <div className={styles.signupWrapper}>
                    <div className={styles.signupFormContainer}>
                        <div className={styles.signupHeader}>Create account</div>
                        <form className={styles.signupForm} onSubmit={(e) => attemptRegistration(e)}>
                            <FormInputField id='first_name' labelName='First Name' value={fields['first_name']} handleChange={handleChange} error={emptyError.first_name}/>
                            <FormInputField id='last_name' labelName='Last Name' value={fields['last_name']} handleChange={handleChange} error={emptyError.last_name}/>
                            <FormInputField id='email' type='email' labelName='Email' value={fields['email'] || userEmail} handleChange={handleChange} error={emptyError.email}/>
                            <FormInputField id='password' type='password' labelName='Password' value={fields['authentication'].new_password} handleChange={handleChange} error={emptyError.password}/>
                            <FormInputField id='confirm_password' type='password' labelName='Confirm Password' value={fields['confirm_password']} handleChange={handleChange} error={emptyError.confirm_password}/>
                            <div className="formField">
                                <Button className={styles.signupSubmitButton} level="primary" type="buttonSubmit">Create account</Button>
                            </div>
                            
                            <Dialog open={dialogMessage ? true : false} title="Account creation unsuccessful" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>{dialogMessage}</Dialog>
                            <div className={styles.signupLoginText}>Already have an account? <Link to="/sign-in">Sign in</Link></div>
                        </form>
                    </div>
                </div>
                <div className={styles.signupBannerWrapper} style={!!backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : undefined}>
                    <div>{data?.signupPage?.pageTitle}</div>
                </div>
            </div>
        </Layout>
        )
    } else {
        return null;
    }
  }
  
export default ContentfulPageSignup

export const query = graphql`
query ContentfulPageSignup {
    signupPage: contentfulPage(pageName: {eq: "Create Account"}) {
        pageName
        pageTitle
        blocks {
            blocks {
                ... on ContentfulBlockImage {
                    desktopImage {
                        file {
                            url
                        }
                    }
                    mobileImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
}
`
  